import { graphql } from 'gatsby';
import React from 'react';

import StandardSEO from '../components/common/seo/StandardSEO';
import styles from '../components/common/styling/styles';
import ChristmasOptionsSection from '../components/PageComps/ChristmasPage2023Comps/ChristmasOptionsSection';
import ChristmasTestimonials from '../components/PageComps/ChristmasPage2023Comps/ChristmasTestimonials';
import GetStartedWithFanGo from '../components/PageComps/ChristmasPage2023Comps/GetStartedWithFanGo';
import SimplePageHeadChristmas from '../components/PageComps/ChristmasPage2023Comps/SimplePageHeadChristmas';
import WhyChooseFanGoAtChristmas from '../components/PageComps/ChristmasPage2023Comps/WhyChooseFanGoAtChristmas';
import { TrustedBrandIcons } from '../components/PageComps/CommonComps';

const ChristmasPage2023 = ({ data }) => {
  const { sanityChristmasPage2023 } = data;
  const {
    pageHead,
    christmasExperienceOptions,
    whyChooseFanGoAtChristmas,
    getStartedWithFanGo,
    titleBarDecorationImage,
    testimonials,
    christmasPageIntroSection,
  } = sanityChristmasPage2023;
  const { asset } = titleBarDecorationImage;
  const decorationImage = asset?.gatsbyImageData;

  return (
    <>
      <SimplePageHeadChristmas data={pageHead} />
      <ChristmasOptionsSection
        christmasPageIntroSection={christmasPageIntroSection}
        christmasExperienceOptions={christmasExperienceOptions}
        decorationImage={decorationImage}
      />
      <WhyChooseFanGoAtChristmas
        fangoSoftware={whyChooseFanGoAtChristmas}
        decorationImage={decorationImage}
      />
      <GetStartedWithFanGo
        getStartedWithFanGo={getStartedWithFanGo}
        decorationImage={decorationImage}
      />
      <ChristmasTestimonials
        testimonials={testimonials}
        decorationImage={decorationImage}
      />
      <TrustedBrandIcons
        data={data.sanityCommonContent.trustedBy}
        paddingBottom={styles.allEight}
      />
    </>
  );
};

export default ChristmasPage2023;

export const Head = ({ data }) => (
  <StandardSEO SEOData={data.sanityChristmasPage2023.seo} />
);

export const query = graphql`
  query {
    sanityChristmasPage2023 {
      seo {
        title
        description
        keywords
        name
        type
      }
      pageHead {
        title
      }
      titleBarDecorationImage {
        asset {
          gatsbyImageData
        }
        altText
      }
      christmasPageIntroSection {
        title
        text
      }
      titleBackgroundImage {
        asset {
          url
        }
        altText
      }
      christmasExperienceOptions {
        title
        _rawBody
        festiveOptionsList {
          _key
          cardTitle
          cardBackText
          cardImage {
            asset {
              url
            }
            altText
          }
        }
      }
      whyChooseFanGoAtChristmas {
        title
        _rawBody
        softwareOptions {
          options {
            title
            _rawBody
          }
          image {
            asset {
              url
            }
          }
        }
      }
      getStartedWithFanGo {
        title
        text
        image {
          asset {
            url
          }
        }
      }
      testimonials {
        title
        _rawBody
        clientTestimonials {
          clientLogoImage {
            asset {
              gatsbyImageData(placeholder: BLURRED, width: 300, formats: [WEBP])
            }
          }
          clientPropertyImage {
            asset {
              url
            }
          }
          clientTestimony {
            jobTitle
            testimony
            company
            companyWebsiteURL
            clientName
          }
        }
      }
    }
    sanityCommonContent(language: { eq: "en" }) {
      trustedBy {
        title
      }
      backgrounds {
        asset {
          url
        }
        altText
      }
    }
  }
`;

// image {
//   asset {
//     gatsbyImageData
//     altText
//   }
// }

// export const query = graphql`
//   query {
//     sanityChristmasPage2023 {
//       seo {
//         title
//         description
//         keywords
//         name
//         type
//       }
//       pageHead {
//         title
//         subTitle
//         videoID
//       }
//       titleBarDecorationImage {
//         asset {
//           gatsbyImageData(placeholder: BLURRED, height: 800)
//         }
//         altText
//       }
//       christmasExperienceOptions {
//         title
//         festiveOptionsList {
//           _key
//           cardTitle
//           cardBackText
//           cardImage {
//             asset {
//               url
//             }
//             altText
//           }
//         }
//       }
//       whyChooseFanGoAtChristmas {
//         title
//         softwareOptions {
//           options {
//             text
//             title
//           }
//           image {
//             asset {
//               url
//             }
//           }
//         }
//       }
//       getStartedWithFanGo {
//         title
//         text
//         image {
//           asset {
//             gatsbyImageData(placeholder: BLURRED, height: 800)
//           }
//           altText
//         }
//       }
//     }
//     sanityCommonContent(language: { eq: $language }) {
//       trustedBy {
//         title
//       }
//       backgrounds {
//         asset {
//           url
//         }
//         altText
//       }
//     }
//   }
// `;

{
  /* <div
        style={{
          position: 'relative',
          width: '100%',
          height: '100%',
        }}
      >
        // <VideoPlayer videoID={videoID} loop={true} controls={false} />
      </div>
      <div
        style={{ position: 'absolute', height: '100%', width: '100%', top: 0 }}
      >
        <SimplePageHead data={pageHead} />
      </div> */
}
{
  /* 
      <Box
        sx={{
          flexWrap: 'wrap',
          width: '100%',
          minHeight: '100%',
          margin: 'auto',
          backgroundColor: 'red',
        }}
      >
        <ReactPlayer
          id="MyId"
          width="100%"
          style={{
            position: 'absolute',
            overflow: 'hidden',
            top: 0,
            left: 0,
          }}
          controls={false}
          playing
          muted
          loop
          url={url}
        ></ReactPlayer>
      </Box> */
}
