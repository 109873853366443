import React, { useEffect, useState } from 'react';
import { TypeAnimation } from 'react-type-animation';

import { Theme, useTheme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

import christmasBackground from '../../../assets/christmasBackground.mp4';
import useGetScreenSize from '../../../hooks/useGetScreenSize';
import PageHeadVideo from '../../common/pageHeads/PageHeadVideo';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textContainer: {
      position: 'absolute',
      left: 0,
      bottom: 0,
      textAlign: 'center',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    container: {
      position: 'relative',
      backgroundColor: 'white',
      maxHeight: '1000px',
      overflow: 'hidden',
    },
    backgroundVideo: {
      height: '100%',
      width: '100%',
      objectFit: 'cover',
    },
  })
);

const SimplePageHeadChristmas = ({ data }) => {
  const { title: pageTitle } = data;
  const classes = useStyles();
  const [fontSize, setFontSize] = useState('');
  const theme = useTheme();
  const isBrowser = typeof window !== 'undefined';

  // const getFontSize = () => {
  //   const screenSize = useGetScreenSize();
  //   console.log(screenSize);
  //   let fontSize: string = '';
  //   switch (screenSize) {
  //     case 'sm':
  //       fontSize = '3rem';
  //       break;
  //     case 'md':
  //       fontSize = '4rem';
  //       break;
  //     case 'lg':
  //       fontSize = '5rem';
  //       break;
  //     case 'xl':
  //       fontSize = '6rem';
  //       break;
  //   }
  //   console.log(fontSize);
  //   return fontSize;
  // };

  // const handleScreenSize = () => {
  //   if (isBrowser && window) {
  //     const windowWidth = window.innerWidth;
  //     if (windowWidth <= theme.breakpoints.values.sm) {
  //       setFontSize('3rem');
  //     } else if (windowWidth <= theme.breakpoints.values.md) {
  //       setFontSize('4rem');
  //     } else if (windowWidth <= theme.breakpoints.values.lg) {
  //       setFontSize('5rem');
  //     } else if (windowWidth <= theme.breakpoints.values.xl) {
  //       setFontSize('6rem');
  //     }
  //   }
  // };

  // useEffect(() => {
  //   if (isBrowser && window) {
  //     window.addEventListener('resize', handleScreenSize);
  //     return () => {
  //       window.removeEventListener('resize', handleScreenSize);
  //     };
  //   }
  // }, []);

  const getFontSize = () => {
    let fontSize: string = '';
    if (isBrowser && window) {
      const windowWidth = window.innerWidth;
      if (windowWidth <= theme.breakpoints.values.sm) {
        fontSize = '3rem';
      } else if (windowWidth <= theme.breakpoints.values.md) {
        fontSize = '4rem';
      } else if (windowWidth <= theme.breakpoints.values.lg) {
        fontSize = '5rem';
      } else if (windowWidth >= theme.breakpoints.values.lg) {
        fontSize = '5.5rem';
      }
    }

    return fontSize;
  };

  const CentralContent = (
    <div className={classes.textContainer}>
      <TypeAnimation
        sequence={[pageTitle]}
        wrapper="h1"
        speed={20}
        cursor={false}
        style={{
          whiteSpace: 'pre-line',
          color: 'white',
          fontFamily: 'bodoni',
          fontStyle: 'italic',
          fontSize: getFontSize(),
        }}
      />
    </div>
  );

  return (
    <PageHeadVideo
      backgroundVideo={christmasBackground}
      CentralContent={CentralContent}
      videoShading={0.2}
      scrollDownButton={false}
      leftGridSpacing={false}
      rightGridSpacing={false}
    />
  );
};

export default SimplePageHeadChristmas;
