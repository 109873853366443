import React from 'react';

import { Typography } from '@mui/material';

import { getCardArray } from '../../../utils/getCardArray';
import getSoftwareFeaturesStyles from '../../../utils/getSoftwareFeaturesStyles';
import Body from '../../common/body/Body';
import { FanGoTextProcessor } from '../../common/portableText';
import styles from '../../common/styling/styles';
import { MainText, SubText } from '../../common/texts';
import FlippyCards from '../CommonComps/FlippyCards';
import AnimatedStarBar from './AnimatedStarBar';

const ChristmasOptionsSection = ({
  christmasExperienceOptions,
  decorationImage,
  christmasPageIntroSection,
}) => {
  const { title, _rawBody, festiveOptionsList } = christmasExperienceOptions;
  const { title: introTitle, text: introText } = christmasPageIntroSection;

  const festiveCards = festiveOptionsList.map((card) => {
    return {
      _key: card._key,
      backgroundImage: card.cardImage,
      feature: card.cardTitle,
      description: card.cardBackText,
    };
  });

  const festiveCardsData = {
    productFeatures: festiveCards,
  };

  const featureStyles = getSoftwareFeaturesStyles({
    page: 'christmasOptionsSection',
  });

  const cardArray = getCardArray({
    data: festiveCardsData,
    featureStyles,
    parentPage: 'christmas',
  });

  return (
    <Body paddingY={styles.allFive}>
      <Typography
        variant="h2"
        align="center"
        sx={{ color: styles.gold, fontSize: '2em' }}
      >
        {introTitle}
      </Typography>
      <Typography align="center" sx={{ color: styles.black, mb: 7, mt: 3 }}>
        {introText}
      </Typography>
      <AnimatedStarBar img={decorationImage} />
      <Typography
        variant="h2"
        align="center"
        sx={{ color: styles.gold, fontSize: '2em' }}
      >
        {title}
      </Typography>
      <FanGoTextProcessor text={_rawBody} textAlign="center" />

      <FlippyCards
        cardArray={cardArray}
        defaultCols={3}
        defaultRows={3}
        speed={500}
      />
    </Body>
  );
};

export default ChristmasOptionsSection;
