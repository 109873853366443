import React from 'react';

import { Box } from '@mui/material';

import { FanGoGridCarousel } from '../../common/carousel';
import CustomGridCarousel from '../../common/carousel/CustomGridCarousel';

const responsiveSizes = [
  {
    breakpoint: 1200,
    cols: 3,
    rows: 3,
  },
  {
    breakpoint: 899,
    cols: 2,
    rows: 2,
  },
  {
    breakpoint: 600,
    cols: 2,
    rows: 1,
  },
  {
    breakpoint: 500,
    cols: 1,
    rows: 1,
  },
];

const FlippyCards = ({
  cardArray,
  defaultRows = null,
  defaultCols = null,
  speed = null,
}) => {
  return (
    <>
      <Box sx={{ display: { xs: 'block', md: 'none' } }}>
        <CustomGridCarousel
          itemsArray={cardArray}
          responsiveSizes={responsiveSizes}
          speed={speed}
        />
      </Box>

      <Box sx={{ display: { xs: 'none', md: 'block' } }}>
        {/* <FanGoGridCarousel
          array={cardArray}
          responsiveLayout={responsiveSizes}
          mobileBreakpoint={10}
          hideArrow={true}
          showDots={false}
        /> */}
        <CustomGridCarousel
          itemsArray={cardArray}
          responsiveSizes={responsiveSizes}
          defaultRows={defaultRows}
          defaultCols={defaultCols}
          speed={speed}
        />
      </Box>
    </>
  );
};

export default FlippyCards;
