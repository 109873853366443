import React from 'react';

import { Box } from '@mui/material';

import Body from '../../common/body/Body';
import { FanGoTextProcessor } from '../../common/portableText';
import styles from '../../common/styling/styles';
import getTestimonialCards from '../../common/testimonials/getTestimonialCards';
import { MainText } from '../../common/texts';
import BlogsCarousel from '../CommonComps/blogsPreview/BlogsCarousel';
import AnimatedStarBar from './AnimatedStarBar';

const ChristmasTestimonials = ({ testimonials, decorationImage }) => {
  const { title, clientTestimonials, _rawBody } = testimonials;

  const cardArray = getTestimonialCards({
    testimonials: clientTestimonials,
  });

  return (
    <Body paddingY={styles.allZero}>
      <AnimatedStarBar img={decorationImage} />
      <MainText textVariant="h2" fontColor={styles.gold} value={title} />
      <FanGoTextProcessor text={_rawBody} textAlign="center" />
      <Box sx={{ mt: 3, pb: 3 }}>
        <BlogsCarousel array={cardArray} />
      </Box>
    </Body>
  );
};

export default ChristmasTestimonials;
