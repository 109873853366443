import React from 'react';

import { Box } from '@mui/material';

import Feature from '../components/features/Feature';

export const getCardArray = ({ data, featureStyles, parentPage }) => {
  const cardArray = data.productFeatures.map((feature) => {
    const { internalLink = '' } = feature;

    if (internalLink) {
      return (
        <a
          href={internalLink}
          style={{ height: '100%', textDecoration: 'none' }}
        >
          <Box sx={{ p: 1.5, height: '100%' }}>
            <Feature
              data={feature}
              key={feature._key}
              featureStyles={featureStyles}
              parentPage={parentPage}
            />
          </Box>
        </a>
      );
    }
    return (
      <Box
        sx={{
          p: 1.5,
          height: '100%',
        }}
      >
        <Feature
          data={feature}
          key={feature._key}
          featureStyles={featureStyles}
          parentPage={parentPage}
          paddingBottom={20}
          minHeight={200}
        />
      </Box>
    );
  });
  return cardArray;
};
