import React, { useEffect, useState } from 'react';

import { getDimensions } from './pageHeadUtils';

const useUpdateDimensionsHook = ({
  setDimensions,
  videoHeight,
  dimensions,
}) => {
  useEffect(() => {
    const isBrowser = typeof window !== 'undefined';
    if (isBrowser && window) {
      const updateDimensions = () => {
        const { width, height, minHeight } = getDimensions({ videoHeight });
        setDimensions({
          width,
          height,
          minHeight,
        });
        if (dimensions.initialWidth !== dimensions.width) {
        }
      };
      window.addEventListener('resize', updateDimensions);
      return () => {
        window.removeEventListener('resize', updateDimensions);
      };
    }
  }, []);
};

export default useUpdateDimensionsHook;
