import BackgroundImage from 'gatsby-background-image';
import { getImage } from 'gatsby-plugin-image';
import { convertToBgImage } from 'gbimage-bridge';
import React, { useState } from 'react';

import { Box, Grid, Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

import FullImageBackgroundHeader from '../../PageComps/CommonComps/heroSections/FullImageBackgroundHeader';
import AnimatedScrollDownButton from '../../PageComps/HomePageComps/AnimatedScrollDownButton';
import { getDimensions, PageHeadVideoProps } from './pageHeadUtils';
import useUpdateDimensionsHook from './useUpdateDimensionsHook';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      position: 'relative',
      backgroundColor: 'white',
      maxHeight: '1000px',
    },
    backgroundVideo: {
      height: '100%',
      width: '100%',
      objectFit: 'cover',
    },
    centerMdDown: {
      [theme.breakpoints.down('md')]: {
        textAlign: 'center',
      },
    },
    imagesContainer: {
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
    },
    contentContainer: (videoShading) => ({
      backgroundColor: `rgba(0, 0, 0, ${videoShading})`,
    }),
  })
);

const PageHeadVideo = ({
  backgroundVideo,
  LeftSideContent = undefined,
  RightSideContent = undefined,
  scrollDownButton = false,
  leftGridSpacing = false,
  rightGridSpacing = false,
  CentralContent = undefined,
  videoShading = 0,
  videoHeight = 0.8,
  placeholderImg = '',
  autoPlayVideo = true,
}: PageHeadVideoProps) => {
  const classes = useStyles(videoShading);
  const [dimensions, setDimensions] = useState(getDimensions({ videoHeight }));
  useUpdateDimensionsHook({ setDimensions, videoHeight, dimensions });
  const [videoPlaying, setVideoPlaying] = useState(false);

  let initialHeight: number;
  const isBrowser = typeof window !== 'undefined';
  if (isBrowser) {
    initialHeight = window.innerHeight * videoHeight;
  }

  const videoReadyToPlay = () => {
    console.log('Video is ready to play');
    //setVideoPlaying(true);
  };

  const image = getImage(placeholderImg);
  const bgImage = convertToBgImage(image);

  return (
    <Box className={classes.container}>
      {!videoPlaying && (
        <BackgroundImage
          style={{
            height: dimensions.height,
            backgroundColor: '#141855',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
          }}
          {...bgImage}
        ></BackgroundImage>
      )}
      <Box sx={{ height: initialHeight }} style={dimensions}>
        <video
          className={classes.backgroundVideo}
          loop
          autoPlay
          muted
          playsInline
          onCanPlay={videoReadyToPlay}
          poster={placeholderImg}
          onCanPlayThrough={() => videoReadyToPlay}

          // style={{ height: '100%', width: '100%' }}
        >
          <source src={backgroundVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        {/* <iframe
          src="https://player.vimeo.com/video/995000013?autoplay=1&loop=1&autopause=0&controls=0&muted=1"
          className={classes.backgroundVideo}
        ></iframe> */}
      </Box>
      <Box
        className={classes.contentContainer}
        sx={{
          position: 'absolute',
          top: 0,
          width: '100%',
          display: 'flex',
          height: dimensions.height,
          minHeight: dimensions.minHeight,
        }}
      >
        <Grid container sx={{ pr: 1, pl: 1 }}>
          {CentralContent && (
            <Grid item xs={12}>
              {CentralContent}
            </Grid>
          )}

          {leftGridSpacing && <Grid item xs={0} md={1} lg={1} />}

          {!CentralContent && LeftSideContent && (
            <Grid
              item
              xs={12}
              md={5}
              lg={5}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              {LeftSideContent}
            </Grid>
          )}

          {!CentralContent && RightSideContent && (
            <Grid item xs={0} md={6} lg={5} sx={{ pt: '100px', pb: '20px' }}>
              <Box
                className={classes.imagesContainer}
                sx={{
                  height: '100%',
                  width: '100%',
                  position: 'relative',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {RightSideContent}
              </Box>
            </Grid>
          )}

          {rightGridSpacing && <Grid item xs={0} lg={1} />}
        </Grid>
      </Box>
      {scrollDownButton && (
        <div
          style={{
            width: '100%',
            position: 'absolute',
            bottom: 0,
          }}
        >
          <AnimatedScrollDownButton />
        </div>
      )}
    </Box>
  );
};

export default PageHeadVideo;
