import { Box } from '@mui/material';
import { motion, useAnimation } from 'framer-motion';
import { GatsbyImage } from 'gatsby-plugin-image';
import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

const AnimatedStarBar = ({ img }) => {
  const Bar = ({ rightBar = false }) => {
    const controls = useAnimation();
    const [ref, inView] = useInView();
    useEffect(() => {
      if (inView) {
        if (rightBar) {
          controls.start({ width: '45%', transition: { duration: 2 } });
        } else {
          controls.start({ width: '45%', transition: { duration: 2 } });
        }
      }
    }, [controls, inView]);

    return (
      <motion.div
        ref={ref}
        animate={controls}
        initial="hidden"
        translate="yes"
        style={{
          width: rightBar ? '0' : '0',
          position: rightBar ? 'absolute' : 'relative',
          right: rightBar ? 0 : 0,
          height: 1,
          background: '#CBA429',
        }}
      ></motion.div>
    );
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        position: 'relative',
        alignItems: 'center',
        margin: 'auto',
        pb: 3,
      }}
    >
      <Bar />
      <div
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'absolute',
          left: 0,
        }}
      >
        <GatsbyImage
          image={img}
          alt={'logo'}
          objectFit="contain"
          style={{
            maxWidth: '200px',
          }}
        />
      </div>
      <Bar rightBar />
    </Box>
  );
};

export default AnimatedStarBar;
