import styles from '../components/common/styling/styles';

const getSoftwareFeaturesStyles = ({ page }) => {
  const featureStyles = {
    iconBackgroundColor: '',
    iconColor: '',
    cardColorFront: '',
    cardColorBack: '',
    fontColorCardFront: '',
    fontColorCardBack: '',
  };

  switch (page) {
    case 'homepage':
    case 'ppc':
      featureStyles.iconBackgroundColor = styles.deepGreen;
      featureStyles.iconColor = styles.white;
      featureStyles.cardColorFront = styles.white;
      featureStyles.cardColorBack = styles.deepGreen;
      featureStyles.fontColorCardFront = styles.grey;
      featureStyles.fontColorCardBack = styles.white;
      break;

    case 'wedding':
      featureStyles.iconBackgroundColor = 'none';
      featureStyles.iconColor = styles.white;
      featureStyles.cardColorFront = styles.deepGreen;
      featureStyles.cardColorBack = styles.white;
      featureStyles.fontColorCardFront = styles.white;
      featureStyles.fontColorCardBack = styles.grey;
      break;

    case 'christmasOptionsSection':
      featureStyles.iconBackgroundColor = 'none';
      featureStyles.fontColorCardBack = styles.black;
      featureStyles.fontColorCardFront = styles.white;
      break;

    default:
      return null;
  }

  return featureStyles;
};

export default getSoftwareFeaturesStyles;
