import React from 'react';

import { Box, Grid, Hidden, Typography } from '@mui/material';

import Body from '../../common/body/Body';
import { FanGoTextProcessor } from '../../common/portableText';
import styles from '../../common/styling/styles';
import { MainText, SubText } from '../../common/texts';
import AnimatedStarBar from './AnimatedStarBar';

const WhyChooseFanGoAtChristmas = ({ fangoSoftware, decorationImage }) => {
  const { title, softwareOptions, _rawBody } = fangoSoftware;
  return (
    <Body
      backgroundColor={styles.offWhite}
      paddingY={styles.allFive}
      paddingTop={styles.allZero}
      paddingBottom={styles.allZero}
    >
      <AnimatedStarBar img={decorationImage} />

      <MainText textVariant="h2" fontColor={styles.gold} value={title} />
      <Box sx={{ mb: 3 }}>
        <FanGoTextProcessor text={_rawBody} textAlign="center" />
      </Box>
      {softwareOptions.map((softwareOptionItem, index) => {
        const alternate = (index + 1) % 2 === 0;
        const { options, image } = softwareOptionItem;
        const imageUrl = image?.asset.url;

        return (
          <Grid
            key={options[index].title}
            container
            alignItems="center"
            justifyContent="center"
            margin="auto"
            marginBottom={5}
            sx={[
              {
                flexDirection: {
                  md: alternate ? 'row-reverse' : 'row',
                  xs: 'column',
                },
              },
            ]}
          >
            <Grid item xs={12} md={6} lg={5}>
              {options.map((option) => {
                const { title, _rawBody } = option;
                return (
                  <Box sx={{ mb: 3 }} key={`${title}-option`}>
                    <Typography
                      variant="h3"
                      sx={{
                        color: styles.darkBlue,
                        px: 0,
                        py: 1,
                        textAlign: { md: 'left', xs: 'center' },
                      }}
                    >
                      {title}
                    </Typography>
                    <FanGoTextProcessor text={_rawBody} linkColor="#cba429" />
                  </Box>
                );
              })}
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              lg={7}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Hidden mdDown>
                <img
                  src={imageUrl}
                  style={{
                    width: '100%',
                    height: '100%',
                    marginLeft: alternate ? 0 : 25,
                    marginRight: alternate ? 25 : 0,
                  }}
                />
              </Hidden>
              <Hidden mdUp>
                <div
                  style={{
                    backgroundColor: 'red',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    overflow: 'hidden',
                  }}
                >
                  <img
                    src={imageUrl}
                    style={{
                      width: '100%',
                      minWidth: '400px',
                      height: '100%',
                    }}
                  />
                </div>
              </Hidden>
            </Grid>
          </Grid>
        );
      })}
    </Body>
  );
};

export default WhyChooseFanGoAtChristmas;
