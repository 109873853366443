import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';

import { Box, Grid, Theme, Typography, useTheme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

import Body from '../../common/body/Body';
import DemoButton from '../../common/button/DemoButton';
import Sparkles from '../../common/sparkleComp/Sparkles';
import styles from '../../common/styling/styles';
import { MainText, SubText } from '../../common/texts';
import { TrustedBrandIcons } from '../CommonComps';
import AnimatedStarBar from './AnimatedStarBar';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    imageContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'rgba(0, 0, 0, 0.2)',
      [theme.breakpoints.up('sm')]: {
        paddingTop: '10rem',
        paddingBottom: '10rem',
      },
      [theme.breakpoints.down('sm')]: {
        paddingTop: '5rem',
        paddingBottom: '5rem',
      },
      borderRadius: '24px',
    },
  })
);

const GetStartedWithFanGo = ({ getStartedWithFanGo, decorationImage }) => {
  const classes = useStyles();
  const { title, text, image } = getStartedWithFanGo;
  const { asset } = image;
  const { url } = asset;
  const CTAbuttonName = (
    <MainText
      textVariant="h4"
      value="Request a Demo"
      paddingX={styles.allZero}
      marginY={styles.allZero}
    />
  );

  return (
    <Body
      paddingTop={styles.allZero}
      paddingBottom={styles.allZero}
      paddingY={styles.allFive}
    >
      <AnimatedStarBar img={decorationImage} />
      <MainText
        textVariant="h2"
        fontColor={styles.gold}
        paddingBottom={styles.allThree}
        value={title}
      />

      <Box
        sx={{
          backgroundImage: `url(${url})`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: 3,
        }}
      >
        <div className={classes.imageContainer}>
          <Grid container>
            <Grid item xs={2} md={4} lg={2} />
            <Grid
              item
              xs={8}
              md={4}
              lg={8}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                gap: 10,
              }}
            >
              <Typography variant="h5" textAlign="center">
                {text}
              </Typography>
              <Box sx={{ textAlign: 'center' }}>
                <DemoButton
                  buttonName={CTAbuttonName}
                  style={{ fontSize: 30 }}
                  currentPage="christmas"
                />
              </Box>
            </Grid>
            <Grid item xs={2} md={4} lg={2} />
          </Grid>
        </div>
      </Box>
    </Body>
  );
};

export default GetStartedWithFanGo;

{
  /* <GatsbyImage
        image={gatsbyImageData}
        alt={'logo'}
        objectFit="contain"
        style={{
          width: '100vw',
          position: 'absolute',
          right: 0,
          zIndex: 0,
        }}
      /> */
}
