export const getDimensions = ({ videoHeight = 0.8 }) => {
  const isBrowser = typeof window !== 'undefined';
  if (isBrowser) {
    const width = window.innerWidth;
    const height = window.innerHeight;

    let maxHeight = Math.min(height * videoHeight, 1000);

    return {
      width: '100%',
      height: `${maxHeight}px`,
      minHeight: '650px',
      initialWidth: width,
    };
  }
  return { width: '100vw', height: '600px', minHeight: '650px' };
};

export type PageHeadVideoProps = {
  backgroundVideo: string;
  LeftSideContent?: React.ReactNode;
  RightSideContent?: React.ReactNode;
  CentralContent?: React.ReactNode;
  scrollDownButton: boolean;
  leftGridSpacing: boolean;
  rightGridSpacing: boolean;
  videoShading?: number;
  videoHeight?: number;
  placeholderImg?: string;
  autoPlayVideo?: boolean;
};
